'use strict'
import router from '@/router'
import api from '@/api/api'
import notify from "@/utils/notify";
import userService from "@/api/userService";
import organizationService from "@/api/organizationService";
import {encrypt} from "@/utils/crypto";
import activityWatcher from "@/utils/activityWatcher";

export default {

  async userLogin(json) {
    const data = {
      method: 'post',
      url: 'login',
      body: json,
    }

    const response = await api.api(data)
    this.processAccessResponse(response, json.email, data.url)
  },

  async userSignUp(json) {
    const data = {
      method: 'post',
      url: 'signup',
      body: json,
    }

    const response = await api.api(data)
    this.processAccessResponse(response, json.email, data.url)

  },

  processAccessResponse(response, email, url) {
    let next = sessionStorage.getItem('next')
    let service = sessionStorage.getItem('service')
    if (response.status === 200 || response.status === 201) {
      notify.alert.success('OTP sent to your email')
      sessionStorage.setItem('email', email)
      sessionStorage.setItem('otp', '60')
      if (next === null) {
        router.push('/verify-otp')
      } else {
        router.push({ path: 'verify-otp', query: { service: service, next: next } })
      }
    } else if (response.status === 401) {
      notify.alert.error(response.data.message)
      if (next === null) {
        router.push('/' + url)
      } else {
        router.push({ path: '/' + url, query: { service: service, next: next } })
      }
    }
  },

  async verifyOTP(json) {
    const data = {
      method: 'post',
      url: 'verify-otp',
      body: json,
    }

    const response = await api.api(data)
    let next = sessionStorage.getItem('next')
    let service = sessionStorage.getItem('service')
    if (response.status === 200) {
      sessionStorage.setItem('access_token', encrypt(response.data.access_token))
      sessionStorage.setItem('logged_in', encrypt('true'))
      sessionStorage.removeItem('email')
      let profile = await userService.getProfile()
      if (profile.organization_id === null) {
        await router.push('/create-organization')
      } else {
        await organizationService.getOrganization()
        activityWatcher()
        notify.alert.success('Login Successful')
        await router.push('/dashboard')
      }
    } else if (response.status === 401) {
      notify.alert.error(response.data.message)
      if (next === null) {
        await router.push('/verify-otp')
      } else {
        await router.push({path: 'verify-otp', query: { service: service, next: next }})
      }
    }
  },

  async userLogout() {
    const data = {
      method: 'post',
      url: 'logout',
    }

    const response = await api.api(data)
    if (response.status === 200 || response.status === 401) {
      sessionStorage.clear();
      notify.alert.info('Logout Successful')
      window.location.href = '/'
    }
  },

  async resetPassword(json) {
    const data = {
      method: 'post',
      url: 'reset-password',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Password reset successful. Please check your email.')
      router.push('/update-password/' + json.email)
    }

    return response
  },

  async requestOTP(json) {
    const data = {
      method: 'post',
      url: 'request-otp',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      sessionStorage.setItem('otp', '60')
      notify.alert.success('OTP successfully sent. Please check your email.')
      return true
    }
  },

  async updatePassword(json) {
    const data = {
      method: 'post',
      url: 'update-password',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Password successfully updated. Please login.')
      router.push('/login')
    } else if (response.status === 401) {
      notify.alert.error(response.data.message)
    }
  },
}
