import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from "./views/Dashboard";
import Default from "@/components/layouts/Default";
import Blank from "@/components/layouts/Blank.vue";
import Login from "@/views/auth/Login.vue";
import Organization from "@/views/organization/Organization.vue";
import notify from "@/utils/notify";
import Users from "@/views/user/Users.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import UpdatePassword from "@/views/auth/UpdatePassword.vue";
import UserProfile from "@/views/user/UserProfile.vue";
import ChangePassword from "@/views/user/ChangePassword.vue";
import AllOrganizations from "@/views/organization/AllOrganizations.vue";
import VerifyOtp from "@/views/auth/VerifyOtp.vue";
import {decrypt} from "@/utils/crypto";
import SignUp from "@/views/auth/SignUp.vue";
import AddOrganization from "@/views/organization/AddOrganization.vue";
import User from "@/views/user/User.vue";



const router = createRouter({
    history: createWebHistory(),
    routes:[
        { path: '/', redirect: '/dashboard' },
        {
            path: '/',
            component: Default,
            children: [
                {
                    path:'/dashboard',
                    component:Dashboard,
                },
                {
                    path:'/organization',
                    component:Organization,
                },
                {
                    path:'/users',
                    component:Users,
                },
                {
                    path:'/user/:id',
                    component:User,
                },
                {
                    path:'/profile',
                    component:UserProfile,
                },
                {
                    path:'/change-password',
                    component:ChangePassword,
                },
                {
                    path:'/organizations',
                    component:AllOrganizations,
                },
            ]
        },
        {
            path: '/',
            component: Blank,
            children: [
                {
                    path:'/login',
                    component:Login,
                },
                {
                    path:'/signup',
                    component:SignUp,
                },
                {
                    path:'/verify-otp',
                    component:VerifyOtp
                },
                {
                    path:'/create-organization',
                    component:AddOrganization
                },
                {
                    path:'/forgot-password',
                    component:ForgotPassword,
                },
                {
                    path:'/update-password/:email',
                    component:UpdatePassword,
                },
            ],
        },
    ],
});

// User access control
let unauthenticated = ["dashboard", "login", "signup", "verify-otp", "forgot-password", "update-password"]
let neededFiles = ["dashboard", "create-organization", "change-password"]
let emailVerified = ["dashboard", "change-password"];
let superAdmin = ["organization", "users"]

router.beforeEach(async (to, from, next) => {
    let routeTo = to.path.split('/', 2)[1]
    const token = decrypt(sessionStorage.getItem('access_token'))
    if (!unauthenticated.includes(routeTo)) {
        if (token) {
            let profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
            let isSuperAdmin = profile.role === 'primary' || profile.role === 'super_admin'
            if (profile.organization_id === null && routeTo !== 'create-organization') {
                notify.alert.info('Please create your organization first.')
                next('/create-organization')
            }
            let orgData = sessionStorage.getItem('organization')
            let organization = orgData !== null ? JSON.parse(decrypt(orgData)) : {files: []}
            if (profile.email_verified_at === null && !emailVerified.includes(routeTo)) {
                notify.alert.info('First time login. Please change your password.')
                next('/change-password')
            } else if (organization.files.length < 2 && !neededFiles.includes(routeTo)) {
                notify.alert.warning('Please upload your organization files first.')
                next('/dashboard')
            } else if (superAdmin.includes(routeTo) && !isSuperAdmin) {
                notify.alert.warning('You do not have permission to access this page.')
                next('/dashboard')
            }
            next()
        } else {
            next('/login')
        }
        next()
    } else {
        next()
    }
})

export default router;
