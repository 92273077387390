<template>
    <nav class="navbar navigation-bar">
        <slot name="default">
            <div class="cursor-pointer">
            </div>
        </slot>
    </nav>
</template>

<script>

    export default {
        name: "Navbar",
    }
</script>

<style scoped>

</style>
