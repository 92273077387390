'use strict'
import api from '@/api/api'

export default {
    async getServices() {
        const data = {
            method: 'get',
            url: 'services',
        }

        let response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async loadService(id) {
        const data = {
            method: 'get',
            url: 'service/' + id + '/load',
        }

        let response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
}
