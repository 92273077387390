'use strict'
import api from '@/api/api'
import notify from "@/utils/notify";

export default {
    async getRoles(id) {
        const data = {
            method: 'get',
            url: 'roles/' + id,
        }

        let response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createRole(json) {
        const data = {
            method: 'post',
            url: 'role',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('User role created successfully')
            return true;
        }
    },
    async updateRole(json) {
        const data = {
            method: 'patch',
            url: 'role/' + json.id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Role updated successfully')
            return true;
        }
    },
    async deleteRole(id) {
        const data = {
            method: 'delete',
            url: 'role/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Role deleted successfully')
            return true;
        }
    },
}
