<template>
  <div>
    <div class="row">
      <div class="col-md-12 d-flex flex-row justify-content-center">
        <img v-if="!loading" class="img-thumbnail bg-dark-subtle" width="150" :src="avatarUrl + user.avatar" alt="">
      </div>
      <h2 v-if="!loading" class="text-center pt-3">{{ user.first_name }} {{ user.last_name }}</h2>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div class="col-md-12">
        <div v-if="loading" class="text-center">
          <Spinner spinner-class="mb-1" />
        </div>
        <div v-if="!loading">
          <Card body-classes="p-0" class="mb-5">
            <template v-slot:header>
              <div class="d-flex align-items-center justify-content-between">
              <span>
                Roles ({{roles.length}})
                <span class="pb-5">
                  <button v-if="profile.role === 'primary'" class="btn btn-success btn-sm" @click="roleModal = true; roleMode = 'add'">+ Add Role</button>
                </span>
              </span>
              </div>
            </template>
            <div class="col-md-12 table-responsive">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Service</th>
                  <th scope="col">Role</th>
                  <th scope="col">Created</th>
                  <th></th>
                </tr>
                </thead>
                <tbody v-if="!loading">
                <tr v-for="role in roles">
                  <td>{{ role.service.name.toUpperCase() }}</td>
                  <td>{{ role.service.title }}</td>
                  <td>{{ role.role }}</td>
                  <td>{{ formatDate(role.created_at) }}</td>
                  <td>
                    <button
                        v-if="profile.role === 'primary'"
                        class="btn btn-danger btn-sm"
                        @click="confirmDialog('Role', () => deleteRole(role.id))"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-if="loading" class="text-center">
                <Spinner spinner-class="mb-1" />
              </div>
            </div>
            <hr class="border-1 border-info border-opacity-75 mt-3" />
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            </div>
          </Card>
        </div>
      </div>
    </div>

    <!-- Add User Modal -->
    <Modal :open="roleModal" @closed="roleModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="roleMode === 'add'">Add Role</h5>
        <h5 class="modal-title m-0" v-else>Edit Role</h5>
        <button @click="roleModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="role" class="form-label fw-bold h5 mt-3">Service:</label>
              <select data="" class="form-select form-select-lg" @change="getServiceRoles($event)" v-model="role.service_id">
                <option value="" disabled selected>Select service</option>
                <option :disabled="roleMode === 'edit'" v-for="service in services" :data-roles="service.roles" :value="service.id">{{ service.name }}</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="role" class="form-label fw-bold h5 mt-3">Role:</label>
              <select data="" class="form-select form-select-lg" v-model="role.role">
                <option value="" disabled selected>Select role</option>
                <option v-for="role in serviceRoles" :value="role">{{ role }}</option>
              </select>
            </div>
          </div>
        </form>
        <div v-if="roleMode === 'add' || roleMode === 'edit'" class="row">
          <h5 class="mt-4 mb-3">User roles explained</h5>
          <div class="accordion">
            <div class="accordion-item shadow-none" id="primary" @click="accordionToggle('primary')">
              <div class="accordion-header collapsed"><b>Primary admin role</b></div>
              <div class="collapse-holder bg-info-subtle d-none">
                <div class="accordion-body">
                  <div>
                    <ul>
                      <li>This role has full access.</li>
                      <li>Can access all modules and pages.</li>
                      <li>Can create, update, and remove data in all modules.</li>
                      <li>Can approve the final payroll.</li>
                      <li>Can create and manage other users.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item shadow-none" id="admin" @click="accordionToggle('admin')">
              <div class="accordion-header collapsed"><b>Admin role</b></div>
              <div class="collapse-holder bg-info-subtle d-none">
                <div class="accordion-body">
                  <div>
                    <ul>
                      <li>This user has limited access.</li>
                      <li>Cannot access Account and Users module.</li>
                      <li>Can create and update data in allowed modules.</li>
                      <li>Can create and manage the payroll.</li>
                      <li>Cannot approve the final payroll.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item shadow-none" id="user" @click="accordionToggle('user')">
              <div class="accordion-header collapsed"><b>User role</b></div>
              <div class="collapse-holder bg-info-subtle d-none">
                <div class="accordion-body">
                  <div>
                    <ul>
                      <li>This user only has read access.</li>
                      <li>Cannot access Account and Users module.</li>
                      <li>Can only view data in allowed modules.</li>
                      <li>Cannot create, update or remove any data.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="roleModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="roleMode === 'add'" @click="addRole">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateRole()">Update</button>
      </div>
    </Modal>
    <!-- Add User Modal End -->

  </div>
</template>

<script>
import userService from "@/api/userService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";
import roleService from "@/api/roleService";
import confirmDialog from "@/utils/confirmDialog";

export default {
  name: "User",

  data(){
    return {
      profile: {},
      loading: true,
      roleMode: 'add',
      roleModal: false,
      user: {},
      services: [],
      roles: [],
      role: {
        user_id: this.$route.params.id,
        service_id: '',
        role: '',
      },
      serviceRoles: [],
      avatarUrl: process.env.VUE_APP_API_IMAGE_URL + 'user/',
    }
  },

  methods: {
    formatDate,
    confirmDialog,
    getUser: async function () {
      this.user = await userService.getUser(this.$route.params.id)
    },
    getRoles: async function () {
      this.roles = await roleService.getRoles(this.$route.params.id)
    },
    deleteRole: async function (id) {
      let response = await roleService.deleteRole(id)
      if (response)
        await this.getRoles()
    },
    getServiceRoles(e) {
      this.serviceRoles = JSON.parse(e.target.selectedOptions[0].dataset.roles)
    },
    populateRoleEdit(role) {
      this.role = role
      this.serviceRoles = JSON.parse(this.services.find(service => service.id === role.service_id).roles)
    },
    async addRole() {
      let response = await roleService.createRole(this.role)
      if (response)
        this.roleModal = false
        await this.getRoles()
    },
    async updateRole() {
      let response = await roleService.updateRole(this.role)
      if (response)
        this.roleModal = false
        await this.getRoles()
    },
    accordionToggle: function (id) {
      let ids = ["primary", "admin", "user"];
      for (let i = 0; i < ids.length; i++) {
        let accordion = document.getElementById(ids[i]);
        let collapseHolder = accordion.getElementsByClassName("collapse-holder");
        if (ids[i] === id) {
          if (collapseHolder[0].classList.contains("d-none")) {
            collapseHolder[0].classList.remove("d-none");
          } else {
            collapseHolder[0].classList.add("d-none");
          }
        } else {
          collapseHolder[0].classList.add("d-none");
        }
      }
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.services = JSON.parse(sessionStorage.getItem('services'))
    await this.getUser()
    await this.getRoles()
    this.loading = false
  },

  watch: {
    roleModal: function () {
      if (!this.roleModal) {
        this.role = {
          user_id: this.$route.params.id,
        }
      }
    },
  }

}
</script>

<style scoped>

</style>
