<template>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 d-none d-md-block">
      <div style="background-image:url('../../../suite.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 100vh;"
      ></div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
      <div class="row d-flex align-items-md-center justify-content-center vh-100">
        <div class="col-12 col-sm-8 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
          <Card class="bg-white">
            <h2 class="text-center text-uppercase sidebar-brand align-items-center fw-bold logo-text">
              <img src="../../../public/logo.png" width="80px" alt="logo">
            </h2>
            <div class="modal-body p-1">
              <div class="font-size-150 fw-lighter text-center text-uppercase mt-3 mb-4">
                Login
              </div>
              <hr class="my-0" />
              <div class="mt-4">
                <form @submit.prevent="">
                  <UInput v-model="user.email" autofocus placeholder="Email">
                    <template #label>Email address</template>
                  </UInput>
                  <UInput placeholder="Password" type="password" v-model="user.password">
                    <template #label>Password</template>
                  </UInput>
                  <div class="row">
                    <span>
                      <a href="" class="text-info" @click="forgotPassword">Forgot Password?</a>
                    </span>
                  </div>
                  <div class="mt-4 d-flex flex-row justify-content-between">
                    <div class="form-check">
                      <input class="form-check-input bg-info h5" type="checkbox" v-model="user.remember">Remember me
                    </div>
                    <button type="button" class="btn btn-info text-uppercase" :disabled="loading" @click="loginSubmit">
                      <span v-if="!loading">Submit</span>
                      <Spinner v-else size="20" stroke="#ffffff" spinner-class="mt-2"/>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="text-center">
              <br>
              <p class="mb-0">Don't have an account? <router-link class="text-info" to="/signup">Sign Up</router-link></p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import authService from "@/api/authService";
import router from "@/router";
import {decrypt} from "@/utils/crypto";
export default {
  name: 'Login',
  data() {
    return {
      loading: false
    }
  },

  methods: {
    async loginSubmit() {
      this.loading = true
      await authService.userLogin(this.user)
      this.loading = false
    },
    forgotPassword() {
      router.push('/forgot-password')
    }
  },

  created() {
    let loggedIn = decrypt(sessionStorage.getItem('logged_in')) === 'true'
    if (loggedIn) {
      router.push('/dashboard')
    }
    let next = this.$route.query.next
    let service = this.$route.query.service
    if (next !== undefined) {
      sessionStorage.setItem('next', next)
      sessionStorage.setItem('service', service)
    }
  },

  computed: {
    user() {
      if (process.env.NODE_ENV === 'development') {
        return {
          email: 'thomasrakwach@gmail.com',
          password: 'password',
          remember: true
        }
      }
      return {
        email: '',
        password: '',
        remember: false
      }
    }
  }
}
</script>
