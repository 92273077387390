'use strict'
import api from '@/api/api'
import router from '@/router'
import notify from "@/utils/notify"
import {encrypt} from "@/utils/crypto";

export default {
  async getUsers() {
    const data = {
      method: 'get',
      url: 'users',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getUser(id) {
    const data = {
      method: 'get',
      url: 'user/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createUser(json) {
    const data = {
      method: 'post',
      url: 'user',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('User created successfully')
      return true;
    }
  },
  async getProfile() {
    const data = {
      method: 'get',
      url: 'profile',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      sessionStorage.setItem('profile', encrypt(JSON.stringify(response.data)))

      return response.data
    }
  },
  async updateProfile(json) {
    const data = {
      method: 'put',
      url: 'profile',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      await this.getProfile()
      notify.alert.success('Profile updated successfully')
      return true;
    }
  },
  async changePassword(json, id) {
    const data = {
      method: 'post',
      url: 'change-password/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      await this.verifyEmail(id)
      await this.getProfile()
      notify.alert.success('Password updated successfully')
      router.push('/')
    }
  },
  async updateUser(json, id) {
    const data = {
      method: 'put',
      url: 'user/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('User updated successfully')
      return true;
    }
  },
  async updateAvatar(json) {
    const data = {
      method: 'patch',
      url: 'profile/avatar',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      await this.getProfile()
      notify.alert.success('Profile picture updated successfully')
      window.location.reload()
    }
  },
  async verifyEmail(id) {
    const data = {
      method: 'put',
      url: 'user/verify-email/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('User updated successfully')
    }
  },
}
